import { useEffect } from 'react'
import Page from '../components/Page'

/* global Tally */

const Crescendo = () => {
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js'

    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds()
        return
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl) => {
          iframeEl.src = iframeEl.dataset.tallySrc
        })
    }

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load()
      return
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script')
      script.src = widgetScriptSrc
      script.onload = load
      script.onerror = load
      document.body.appendChild(script)
      return
    }
  }, [])

  return (
    <>
      <div
        style={{ backgroundColor: '#e4e2dd' }}
        className="flex flex-col min-h-screen"
      >
        <Page
          title="Amicitia Crescendo"
          logo="/images/graphics/logos/crescendo.png"
          socials={[
            {
              href: 'https://www.facebook.com/people/Amicitia-Crescendo/61566548363267/',
              text: 'Facebook',
            },
            {
              href: 'https://www.instagram.com/amicitiacrescendo/',
              text: 'Instagram',
            },
            {
              href: 'https://linktr.ee/amicitiacrescendo',
              text: 'Linktree',
            },
          ]}
          hero={
            <>
              <div className="flex flex-col gap-20 sm:gap-32">
                <img
                  src="/images/graphics/crescendo/1-1000.png"
                  alt='Text saying "um coro mil vozes"'
                  className="mt-16 px-9 w-full max-w-4xl mx-auto"
                />
                <img
                  src="/images/graphics/crescendo/kids.png"
                  alt="Four little kids singing"
                  className="mx-auto w-44 sm:w-56"
                />
                <div className="px-10 pb-12 text-justify font-medium text-amicitia max-w-md mx-auto sm:mb-12 md:max-w-xl">
                  <p>
                    DESCOBRE A MAGIA DE CANTAR EM CORO NO{' '}
                    <span className="font-extrabold">AMICITIA CRESCENDO</span>!
                  </p>
                  <p>
                    PARA JOVENS{' '}
                    <span className="font-extrabold">DOS 8 AOS 18 ANOS</span>,
                    FAZ NOVOS AMIGOS E DEIXA-TE LEVAR PELA MÚSICA.
                  </p>
                  <p>
                    VEM PARTICIPAR NO NOVO CORO JUVENIL DE PENAFIEL! INSCREVE-TE
                    JÁ E DEIXA A TUA VOZ BRILHAR!
                  </p>
                </div>
              </div>
            </>
          }
        >
          <div className="flex flex-col gap-3 px-10 pb-12 text-justify font-medium text-amicitia max-w-md mx-auto sm:mb-44 md:max-w-xl">
            <p>
              O Coro Amicitia Crescendo é uma fonte de alegria e inspiração,
              unindo crianças e jovens numa caminhada emocionante através da
              música.
            </p>
            <p>
              O Amicitia Crescendo surge com o propósito de enriquecer
              culturalmente e socialmente as suas crianças e jovens,
              preparando-os para crescerem como músicos e como indivíduos,
              prontos para enfrentar os desafios do futuro.
            </p>
            <p>
              Consolidado nos valores de respeito, trabalho coletivo e
              autenticidade, o nosso coro propõe-se a desenvolver a formação
              musical e técnica vocal das suas crianças e jovens, capacitando-os
              assim de ferramentas essencias para o seu futuro.
            </p>
            <p>
              Sob a direção artística de Ricardo Leão (
              <a
                href="https://www.instagram.com/ricardo_aleao/"
                className="underline"
              >
                @ricardo_aleao
              </a>
              ), o Amicitia Crescendo terá acente o seu repertório nas melodias
              e ritmos tradicionais portugueses estimulando o quadro da música
              coral portuguesa. A par, o grupo terá também oportunidade de
              contactar com várias épocas da história da música, explorando
              simultaneamente diferentes estilos, bem como interpretar arranjos
              originais.
            </p>
            <p>
              Com sede na cidade de Penafiel, Amicitia Crescendo visa estimular
              o espírito curioso e propiciar uma visão mais ampla aos nossos
              pequenos cantores.
            </p>
            <p className="font-extrabold text-lg mt-6">
              As aulas já começaram! Todas as segundas-feiras às 19h no
              Recreatório de Penafiel. Novos membros são bem-vindos! Entre em
              contacto connosco para participar e venha cantar conosco!
            </p>
          </div>
          <button
            className="z-10 fixed bottom-4 right-4 bg-amicitia text-white font-bold text-lg px-6 py-3 rounded-full shadow-lg transition-transform transform hover:scale-110 hover:bg-green-800"
            data-tally-open="mVYVpg"
            data-tally-emoji-text="👋"
            data-tally-emoji-animation="wave"
          >
            👋 Pré-inscrição
          </button>
        </Page>
      </div>
    </>
  )
}

export default Crescendo
